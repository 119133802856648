<template>
  <button
    class="
      text-white
      bg-primary-color
      rounded-radius
      block
      w-full
      py-3
      mt-10
      text-lg
      font-medium
    "
    :class="{ 'inactive-state': loading }"
    @click="$emit('sendAction')"
  >
    <slot name="button-text" />
  </button>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>
