<template>
  <onboarding-scaffold>
    <template v-slot:content>
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <div class="w-full flex items-center justify-center">
        <div
          class="
            xl:w-1/2
            lg:w-full
            md:w-full
            w-full
            min-h-screen
            py-20
            xl:px-28
            lg:px-0
            md:px-0
            px-0
          "
        >
          <form-scaffold
            :loading="submitting"
            button-text="Send Email"
            @sendAction="send"
          >
            <span slot="heading-text">Reset your Password</span>
            <div slot="field-instructions">
              Please provide your email address you used in opening this account
              , we will send you a link to reset your password and change to a
              new one.
            </div>
            <div slot="input-fields" class="mb-16">
              <CustomInput
                :fields="{
                  label: 'Email address',
                  fieldName: 'email_address',
                  placeholder: 'e.g legend@suburbanfiberco.com',
                }"
                @input-value="setValue"
              />
              <ValidationMessage v-if="loading && !$v.email_address.required">
                <span slot="message"> Email address is required.</span>
              </ValidationMessage>
              <ValidationMessage v-if="loading && !$v.email_address.email">
                <span slot="message">Enter a valid email address.</span>
              </ValidationMessage>
            </div>
          </form-scaffold>
        </div>
      </div>
    </template>
  </onboarding-scaffold>
</template>

<script>
import OnboardingScaffold from '@/components/OnboardingScaffold.vue'
import FormScaffold from '@/components/FormScaffold.vue'
import CustomInput from '@/components/OnboardingInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'

export default {
  name: 'Reset',
  components: {
    OnboardingScaffold,
    FormScaffold,
    CustomInput,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      email_address: null,
      loading: false,
      prevRoute: null,
      notification: null,
      submitting: false,
    }
  },
  validations() {
    return {
      email_address: { required, email },
    }
  },
  mounted() {
    if (this.prevRoute && this.prevRoute.name === 'Password') {
      this.notification = {
        type: 'error',
        message: this.$route.query.message,
      }
    }
  },
  methods: {
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    async send() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post('/forgot-password', {
          callback_url: `${window.location.origin}/password`,
          email: this.email_address,
        })

        if (status === 200) {
          this.email_address = null
          this.notification = {
            type: 'success',
            message: data.message,
          }
          this.submitting = false

          setTimeout(() => {
            this.$router.push({ name: 'Login' })
          }, 4000)
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
