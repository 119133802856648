<template>
  <div class="mt-6">
    <label
      class="
        text-legend-gray
        dark:text-border-color
        font-semibold
        block
        leading-relaxed
      "
      >{{ fields.label }}</label
    >
    <input
      v-model.trim="inputValue"
      :type="attributeType"
      :placeholder="fields.placeholder"
      class="
        text-seconadry-border
        bg-transparent
        border border-border-color
        rounded-lg
        w-full
        p-3
        focus:outline-none
      "
    />
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    fields: {
      type: Object,
      default: null,
    },
    attributeType: {
      type: String,
      default: 'text',
    },
  },
  watch: {
    inputValue() {
      this.$emit('input-value', {
        value: this.inputValue,
        field: this.fields.fieldName,
      })
    },
  },
  data() {
    return {
      inputValue: null,
    }
  },
}
</script>
