<template>
  <main
    class="
      bg-secondary-background
      dark:bg-black
      w-screen
      absolute
      left-0
      min-h-screen
    "
  >
    <div
      class="
        fixed
        w-20
        xl:right-16
        lg:right-16
        md:right-12
        right-0
        top-10
        text-primary-color
        textsm
        z-50
      "
    >
      <SwithTheme @toggle-mode="changeMode" />
    </div>
    <slot name="content" />
  </main>
</template>

<script>
import SwithTheme from '@/components/UI/Switch.vue'

export default {
  components: { SwithTheme },
  methods: {
    changeMode() {
      this.$store.commit('CHANGE_MODE')
    },
  },
}
</script>
