<template>
  <div
    class="
      lg:w-form-width
      xl:w-form-width
      md:w-form-width
      w-11/12
      pt-12
      pb-14
      lg:px-10
      xl:px-10
      md:px-14
      px-6
      bg-white
      dark:bg-dark-mode-primary dark:text-border-color
      rounded-radius
      shadow-lg
      mx-auto
    "
  >
    <img :src="getImgUrl()" alt="legend pay logo" />
    <h1 class="mt-10 text-2xl font-bold leading-8">
      <slot name="heading-text" />
    </h1>
    <div class="my-6 text-legend-gray dark:text-border-color">
      <slot name="field-instructions" />
    </div>
    <slot name="input-fields" />
    <custom-button :loading="loading" @sendAction="$emit('sendAction')">
      <span slot="button-text">{{ buttonText }}</span>
    </custom-button>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FormSCaffold',
  props: {
    buttonText: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomButton,
  },
  computed: {
    ...mapGetters(['mode']),
  },
  methods: {
    getImgUrl() {
      const imagePath =
        this.mode === 'dark'
          ? 'legend-pay-logo-light.svg'
          : 'legend-pay-logo-dark.svg'
      return require(`@/assets/images/${imagePath}`)
    },
  },
}
</script>
